import { all } from "redux-saga/effects";

import announcements from "./announcements/sagas";
import auth from "./auth/sagas";
import comments from "./comments/sagas";
import course from "./course/sagas";
import dashboard from "./dashboard/sagas";
import favorites from "./favorites/sagas";
import likedislikes from "./likedislike/sagas";
import modules from "./module/sagas";
import news from "./news/sagas";
import notes from "./notes/sagas";
import notifys from "./notifys/sagas";
import pages from "./pages/sagas";
import passwordReset from "./passwordReset/sagas";
import search from "./search/sagas";
import videos from "./videos/sagas";
import relatedCourse from "./relatedCourse/sagas";
//import faq from "./faq/sagas";

export default function* rootSaga() {
  return yield all([
    auth,
    dashboard,
    //faq,
    relatedCourse,
    notifys,
    news,
    course,
    pages,
    passwordReset,
    announcements,
    search,
    modules,
    comments,
    notes,
    likedislikes,
    favorites,
    videos,
    //faq,
  ]);
}
