export function mostRecentOrder(a, b) {
  const aDate = new Date(a.props.date);
  const bDate = new Date(b.props.date);
  if(aDate > bDate){
    return 1;
  } else if(bDate > aDate){
    return -1;
  } else {
    return 0;
  }
}

export function oldestOrder(a, b){
  const aDate = new Date(a.props.date);
  const bDate = new Date(b.props.date);
  if(aDate > bDate){
    return -1;
  } else if(bDate > aDate){
    return 1;
  } else {
    return 0;
  }
}

export function az(a, b){
  if(a.props.title > b.title){
    return 1;
  } else if(b.props.title > a.props.title){
    return -1;
  } else {
    return 0;
  }
}

export function za(a, b){

  if(a.props.title > b.props.title){
    return -1;
  } else if(b.props.title > a.props.title){
    return 1;
  } else {
    return 0;
  }
}


