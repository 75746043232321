import { TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";

export const Container = styled.div`
  span.error {
    font-size: 12px !important;
    text-align: left !important;
    color: ${({ theme }) => theme.palette.error[theme.palette.type]} !important;
  }
`;

export const Input = withStyles(theme => ({
  root: {
    margin: 0,
    width: "100%",
    marginBottom: 20,
    fontFamily: theme.typography.primary,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "20px",
    color: theme.palette.secondary[theme.palette.type],
    "& input": {
      paddingTop: 25,
      paddingBottom: 15
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(14px, 5px) scale(0.75)"
    },
    "& label": {
      color: theme.palette.textTwo[theme.palette.type],
      fontFamily: theme.typography.primary,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "20px",
      transform: "translate(14px, 17.5px)"
    },
    "& legend": {
      display: "none !important"
    },
    "& label.Mui-focused": {
      color: theme.palette.textTwo[theme.palette.type],
      transform: "translate(14px, 5px) scale(0.75)"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green"
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.quaternary[theme.palette.type],
        borderWidth: 2
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary[theme.palette.type]
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary[theme.palette.type]
      }
    }
  }
}))(TextField);
