import { combineReducers } from "redux";

import announcements from "./announcements/reducer";
import auth from "./auth/reducer";
import comments from "./comments/reducer";
import course from "./course/reducer";
import dashboard from "./dashboard/reducer";
import favorites from "./favorites/reducer";
import likedislikes from "./likedislike/reducer";
import modules from "./module/reducer";
import news from "./news/reducer";
import notes from "./notes/reducer";
import notifys from "./notifys/reducer";
import pages from "./pages/reducer";
import passwordReset from "./passwordReset/reducer";
import search from "./search/reducer";
import videos from "./videos/reducer";
//import faq from "./faq/reducer";
import relatedCourse from "./relatedCourse/reducer";

export default combineReducers({
  auth,
  dashboard,
  //faq,
  notifys,
  news,
  course,
  pages,
  passwordReset,
  announcements,
  search,
  modules,
  notes,
  comments,
  likedislikes,
  relatedCourse,
  favorites,
  videos,
});
