
const INITIAL_STATE = {};

export default function user(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@relatedCourse/GET_RELATED_COURSE_SUCCESS":
      return action.payload.data;
    default:
      return state;
  }
}

