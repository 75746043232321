import styled from "styled-components";

/**
 * @todo remover cores em hardcode
 */
export const Container = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 30px;
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    background-color: ${({ theme }) =>
      theme.palette.tertiary[theme.palette.type]};
    border: 1px solid
      ${({ theme }) => theme.palette.quaternary[theme.palette.type]};
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    li {
      margin: 0;
      padding: 0;
      a {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 54px;
        display: flex;
        align-items: center;
        padding: 0 27px;
        border-bottom: 1px solid
          ${({ theme }) => theme.palette.quaternary[theme.palette.type]};
        font-family: ${({ theme }) => theme.typography.fontFamily};
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 17px;
        color: ${({ theme }) => theme.palette.text[theme.palette.type]};
        text-decoration: none;
        transition: all 0.4s ease;
        &.active,
        &:hover {
          background: ${({ theme }) =>
            theme.palette.secondary[theme.palette.type]};
          color: ${({ theme }) => theme.palette.tertiary[theme.palette.type]};
        }
      }
      &:last-child {
        a {
          border-bottom: 0px solid;
        }
      }
    }
  }
`;
