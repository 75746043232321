import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { getPagesRequest } from "~/store/modules/pages/actions";

import { Container } from "./styles";

export default function Sidebar() {
  const dispatch = useDispatch();

  const pages = useSelector((state) => state.pages);

  useEffect(() => {
    dispatch(getPagesRequest());
  }, [dispatch]);

  return (
    <Container>
      {pages.length > 0 && (
        <ul>
          {pages.map((item, index) => (
            <li key={String(index)}>
              <NavLink activeClassName="active" to={item.url}>
                {item.title}
              </NavLink>
            </li>
          ))}
        </ul>
      )}
    </Container>
  );
}
