import styled from "styled-components";

export const Container = styled.div`
  display: block;
  width: 280px;
  position: relative;

  button {
    background-color:#FFF;
    box-shadow: 0 0 0.4em rgba(0,0,0,0.1);
    border:0;
    height: 150px;
    margin: 15px;
    padding-left: 15px;
    padding-top: 15px;
    cursor: pointer;
    border-radius: 5px;
    &:hover,
    &:active,
    &:focus {
      border: 2px solid #5433F1;
    }
  }

  .icons{
    position:absolute;
    padding-top:15px;
    top:10px;
  }
`;

export const CardContent = styled.div`
  position:absolute;
  padding-bottom:0px;
  bottom:0;
  p{
    margin-bottom:0;
    font-size: 12px;
    color: 'gray'
  }
`;
