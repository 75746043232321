const enUS = {
  seeAll: "See all",
  viewOlder: "View older",
  knowMore: "Know more",
  news: "News!",
  of: "of"
};

const ptBR = {
  seeAll: "Ver todos",
  viewOlder: "Ver mais antigos",
  knowMore: "Saiba mais",
  news: "Novidades!",
  of: "de"
};

export default { enUS, ptBR };
