import React, { useState, useRef } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Form } from "@unform/web";

import { Button, Input, Check } from "~/components";
import { ROUTES } from "~/constants/routes";
import { Entrar } from "~/images";
import { signInRequest } from "~/store/modules/auth/actions";
import documentTitle from "~/utils/documentTitle";
import { signin } from "~/validators";

import { Container } from "./styles";

export default function SignIn() {
  documentTitle("Login");

  const { loading } = useSelector((state) => state.auth);

  const form = useRef(null);

  const { formatMessage: _e } = useIntl();

  const dispatch = useDispatch();

  const [remember, setRemember] = useState(false);

  async function handleSubmit(data) {
    await signin(data, form, _e);

    dispatch(
      signInRequest({
        email: data.email,
        password: data.password,
      })
    );
  }

  /**
   * @todo remover alts, labels e material literal hardcode, incluir como internacionalização.
   */
  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <div className="signin">
          <img src={Entrar} alt="Entrar" /> {_e({ id: "login.logIn" })}
        </div>
        <Input label="E-mail" name="email" type="email" required />
        <Input
          label={_e({ id: "login.password" })}
          name="password"
          type="password"
          required
        />
        <div className="login-options">
          <Check
            label={_e({ id: "login.remember" })}
            name="remember"
            checked={remember}
            onClick={() => setRemember(!remember)}
          />
          <Link to={ROUTES.LOST_PASSWORD} className="forget">
            {_e({ id: "login.forgotYourPassword" })}
          </Link>
        </div>
        <Button loading={loading} type="submit">
          {_e({ id: "login.logIn" })}
        </Button>
      </Form>
    </Container>
  );
}
