import React from "react";
import { useIntl } from "react-intl";

import PropTypes from "prop-types";

import { Container } from "./styles";

export default function BoxInfo({ type, quantity }) {
  const { formatMessage: _e } = useIntl();

  function HandleLabel() {
    switch (type) {
      case "finishedCourses":
        return _e({ id: "boxInfo.finishedCourses" });
      case "coursesInProgress":
        return _e({ id: "boxInfo.coursesInProgress" });
      case "hoursStudied":
        return _e({ id: "boxInfo.hoursStudied" });
      case "overallAverage":
        return _e({ id: "boxInfo.overallAverage" });
      default:
        break;
    }
  }

  return (
    <Container>
      <div className={`type ${type}`} />
      <div>
        <div className="label">
          <HandleLabel />
        </div>
        <div className="quantity">{quantity}</div>
      </div>
    </Container>
  );
}

BoxInfo.propTypes = {
  type: PropTypes.string,
  quantity: PropTypes.number,
};

BoxInfo.defaultProps = {
  type: "finishedCourses",
  quantity: 0,
};
