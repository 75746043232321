export function getPagesRequest() {
  return {
    type: "@pages/GET_PAGES_REQUEST"
  };
}

export function getPagesSuccess(data) {
  return {
    type: "@pages/GET_PAGES_SUCCESS",
    payload: { data }
  };
}
