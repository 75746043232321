// import React, { useState } from "react";
// import { MdKeyboardArrowDown } from "react-icons/md";

// import { useTheme } from "@material-ui/core";
// import PropTypes from "prop-types";

// import {
//   Container,
//   AccordionBase,
//   AccordionSummary,
//   AccordionDetails,
//   Bottom,
//   Files,
//   File,
// } from "./styles";

// export default function Accordion({ items, ...rest }) {
//   const theme = useTheme();
//   const [expanded, setExpanded] = useState("");

//   const handleChange = (panel) => (event, newExpanded) => {
//     setExpanded(newExpanded ? panel : false);
//   };
//   return (
//     <Container>
//       {items.map((item, index) => (
//         <AccordionBase
//           key={String(index)}
//           square
//           expanded={expanded === `panel${index}`}
//           onChange={handleChange(`panel${index}`)}
//           {...rest}
//         >
//           <AccordionSummary
//             aria-controls={`panel${index}d-content`}
//             id={`panel${index}d-header`}
//             expandIcon={
//               <MdKeyboardArrowDown
//                 color={theme.palette.primary[theme.palette.type]}
//                 size={20}
//               />
//             }
//           >
//             <div className="accordion-title">
//               <div className="id">{item.id}.</div>
//               <h4>{item.title}</h4>
//             </div>
//           </AccordionSummary>
//           <AccordionDetails>
//             {item.answer}
//             <Bottom>
//               <Files>
//                 {item.file &&
//                   <File>
//                     <a
//                       href={`${process.env.REACT_APP_API_FILE_URL}/FAQ/${item.file}`}
//                     >
//                       {item.file}
//                     </a>
//                   </File>}
//               </Files>
//             </Bottom>
//           </AccordionDetails>
//         </AccordionBase>
//       ))}

//     </Container>
//   );
// }

// Accordion.propTypes = {
//   items: PropTypes.array.isRequired,
// };


import React, { useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";

import { useTheme } from "@material-ui/core";
import PropTypes from "prop-types";

import {
  Container,
  AccordionBase,
  AccordionSummary,
  AccordionDetails,
} from "./styles";

export default function Accordion({ items, ...rest }) {
  const theme = useTheme();
  const [expanded, setExpanded] = useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <Container>
      {items.map((item, index) => (
        <AccordionBase
          key={String(index)}
          square
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
          {...rest}
        >
          <AccordionSummary
            aria-controls={`panel${index}d-content`}
            id={`panel${index}d-header`}
            expandIcon={
              <MdKeyboardArrowDown
                color={theme.palette.primary[theme.palette.type]}
                size={20}
              />
            }
          >
            <div className="accordion-title">
              <div className="id">{item.id}.</div>
              <h4>{item.title}</h4>
            </div>
          </AccordionSummary>
          <AccordionDetails>{item.answer}</AccordionDetails>
        </AccordionBase>
      ))}
    </Container>
  );
}

Accordion.propTypes = {
  items: PropTypes.array.isRequired,
};
