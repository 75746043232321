import styled from "styled-components";

export const Container = styled.div`
  background-color: ${({ theme }) =>
    theme.palette.background[theme.palette.type]};
  .conteudo-curso {
    display: block;
    width: 100%;
    height: 1000px;
  }
  .area-loadding {
    display: block;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .banner-container {
    margin-bottom: 3rem;
    padding-bottom: 3rem;
  }
  .course-preview-wrapper {
    margin-bottom: 3rem;
    padding-bottom: 3rem;
  }
  .related-course-preview{
    background-color: rgb(239,239,239);
    width: 100%;
  }
`;
