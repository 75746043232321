import { TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  .search-icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    border: none;
    height: fit-content;
    width: fit-content;
    background: transparent;
    outline: none;
    padding: 0;
    &:hover,
    &:active,
    &:focus {
      opacity: 0.7;
    }
  }
`;

export const Input = withStyles((theme) => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "normal",
    color: theme.palette.secondary[theme.palette.type],
    margin: 0,
    "& input": {
      padding: "20px 50px 20px 20px",
      height: 20,
      width: 850,
      backgroundColor: theme.palette.tertiary[theme.palette.type],
      fontFamily: theme.typography.fontFamily,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "normal",
      color: theme.palette.secondary[theme.palette.type],
      borderRadius: 5,
      "&:placeholder": {
        fontFamily: theme.typography.fontFamily,
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "normal",
        color: theme.palette.secondary[theme.palette.type],
      },
    },
  },
}))(TextField);
