import React from "react";
import { RawIntlProvider } from "react-intl";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import PropTypes from "prop-types";

import { intl } from "~/messages";
import { store, persistor } from "~/store";

export const AppProvider = ({ children }) => {
  return (
    <Provider store={store}>
      <RawIntlProvider value={intl}>
        <PersistGate persistor={persistor}>{children}</PersistGate>
      </RawIntlProvider>
    </Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string
  ]).isRequired
};
