/* eslint-disable valid-typeof */
/* eslint-disable eqeqeq */
/* eslint-disable no-console */
import { toast } from "react-toastify";

import { takeLatest, call, put, all} from "redux-saga/effects";

import { API, ROUTES } from "~/constants/routes";
import api from "~/services/api";
//import { userInfo } from "~/store/selectors";

import { getRelatedCourseSuccess } from "./actions";

export function* getRelatedCourse({ payload }) {
  const {id_area, id_nivel} = payload;

  try {
    const responseCourse = yield call(api.get, API.RELATED_COURSES, {
      id_area,
      id_nivel,
      id_instituicao: process.env.REACT_APP_ID_INSTITUICAO,
    });
    //console.log(responseCourse.data.data);
    console.log(responseCourse.data.data.curso);
    if (responseCourse.data.errors.length)
      throw new Error(responseCourse.data.errors[0].message);

    const courses = responseCourse.data.data.curso.map((crs) => {
      return {
        area: crs.crs_curso.crs_area.ds_area,
        nivel: crs.crs_curso.crs_nivel.ds_nivel,
        title: crs.crs_curso.ds_curso,
        image: crs.crs_curso.ds_imagem,
        duracaoMeses: crs.crs_curso.nr_duracao_meses,
        duracaoHoras: crs.crs_curso.nr_duracao_horas,
      };
    });

    //console.log(courses);

    yield put(getRelatedCourseSuccess(courses));
  } catch (err) {
    toast.error("Erro ao carregar dados!");
  }
}

export default all([
  takeLatest("@relatedCourse/GET_RELATED_COURSE_REQUEST", getRelatedCourse)
]);
