const enUS = {
  hello: "Hello",
  welcome: "Be welcome!",
  summary: "Check a summary of your activities.",
  announcements: "Announcements",
  recentActivity: "Recent activity",
  recentActivityDesc: "Lorem ipsum dolor asit amet"
};

const ptBR = {
  hello: "Olá",
  welcome: "Seja bem-vindo!",
  summary: "Confira um resumo de suas atividades.",
  announcements: "Comunicados",
  recentActivity: "Atividades recentes",
  recentActivityDesc: "Lorem ipsum dolor asit amet"
};

export default { enUS, ptBR };
