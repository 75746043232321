import React from "react";
import { FaGift } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { useTheme } from "@material-ui/core";
import { parseISO } from "date-fns";
import PropTypes from "prop-types";

import { formatDateHour } from "~/utils/date";

import { Container } from "./styles";

export default function News({ date, description, link, onClose }) {
  const theme = useTheme();

  const { formatMessage: _e } = useIntl();

  return (
    <Container>
      <div className="left">
        <div className="icon">
          <FaGift color={theme.palette.primary[theme.palette.type]} size={20} />
        </div>
        <div className="title">{_e({ id: "global.news" })}</div>
      </div>
      <div className="middle">
        <div className="date">{formatDateHour(parseISO(date))}</div>
        <div className="desc">{description}</div>
      </div>
      <div className="right">
        <Link to={link}>{_e({ id: "global.knowMore" })}</Link>
        <button type="button" onClick={onClose}>
          <MdClose color={theme.palette.tertiary[theme.palette.type]} />
        </button>
      </div>
    </Container>
  );
}

News.propTypes = {
  date: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  onClose: PropTypes.func.isRequired
};

News.defaultProps = {
  date: "",
  description: "",
  link: "/"
};
