import styled from "styled-components";

/**
 * @todo box-shadow com cor hardcode
 */
export const Container = styled.div`
    display: inline-block;
`;

export const CardForSlide = styled.div`

background-color:#FFF;
box-shadow: 0 0 0.4em rgba(0,0,0,0.1);
border:0;
width: 270px;
margin: 15px;
padding: 0; 
border-radius: 5px;

h1 {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    color: ${({ theme }) => theme.palette.secondary[theme.palette.type]};
    margin-top: 10px;
    text-align: initial;
    padding-left: 15px;
} 
.subtitulo-curso{
        font-family: ${({ theme }) => theme.typography.fontFamily};
        font-size: 10px;
        margin-top: 10px;
        text-align: initial;
        padding-left: 15px;
        color: ${({ theme }) => theme.palette.textTwo[theme.palette.type]};
        margin-bottom:10px;
}
.preview {
    border-top-left-radius:5px;
    border-top-right-radius:5px;
    background-color: ${({ theme }) =>
    theme.palette.quaternary[theme.palette.type]};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    height: 130px;
    justify-content: center;

    a {
        width: 40px;
        height: 40px;
        background: ${({ theme }) => theme.palette.tertiary[theme.palette.type]};
        border-radius: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
    }
}

.bottom {
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 1rem;
    grid-auto-flow: column;
    padding:15px;
    margin-top: 15px;

    .info-single {
        text-align:center;
        .label {
            font-family: ${({ theme }) => theme.typography.fontFamily};
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: -0.375px;
            color: ${({ theme }) => theme.palette.textTwo[theme.palette.type]};
        }
        .text {
            font-family: ${({ theme }) => theme.typography.fontFamily};
            font-style: normal;
            font-weight: 800;
            font-size: 14px;
            line-height: 17px;
            color: ${({ theme }) => theme.palette.text[theme.palette.type]};
            text-transform: capitalize;
            margin-bottom: 15px;
            margin-top: 5px;
        }
        a {
            font-family: ${({ theme }) => theme.typography.fontFamily};
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: -0.857143px;
            text-decoration-line: underline;
            color: ${({ theme }) => theme.palette.primary[theme.palette.type]};
        }
    }
}

`;