/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import { element } from "prop-types";
import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';

import { Title, Loading } from "~/components";
import { getFaqSearchRequest, getFaqQuestionsRequest, getFaqCategoriesRequest } from "~/store/modules/faq/actions";
import documentTitle from "~/utils/documentTitle";
// import { isEmpty } from "~/utils/object";

import { Container } from "./styles";

function AcademicSuport() {
  const dispatch = useDispatch();
  //const faq = useSelector((state) => state.faq);
  // const { questions, categories, isLoading } = faq
  const { formatMessage: _e } = useIntl();

  // useEffect(() => {
  //   dispatch(getFaqCategoriesRequest())
  // }, [dispatch]);

  const daysOfWeek = [
    {id: 1, day: 'Segunda', openTime: "8:00", closeTime: '17:00'},
    {id: 2, day: 'Quarta', openTime: "8:00", closeTime: '17:00'},
    {id: 3, day: 'Sexta', openTime: "8:00", closeTime: '17:00'},
    {id: 4, day: 'Sábado', openTime: "8:00", closeTime: '12:00'},
  ]
  const nextRecesses = [
    {id: 1, description: 'Natal', startDate: '2020-12-25', endDate: '2020-12-25', openTime: "8:00", closeTime: '12:00'}
  ]

  documentTitle(_e({ id: "academicSuport.pageTitle" }));

  function TitleAcademicSuport() {
    return `${_e({ id: "academicSuport.pageTitle" })}`;
  }

  return (
    <Container>
      <div className="title-container">
        <Title>
          <TitleAcademicSuport />
        </Title>
      </div>
      <div className="content">
        <div>
          <h2>{_e({ id: "academicSuport.openingHours" })}</h2>
          {daysOfWeek.map(day => 
            <p key={day.id}><b>{day.day}</b>: {day.openTime} - {day.closeTime}</p>
          )}
        </div>
        <div>
          <h2>{_e({ id: "academicSuport.recesses" })}</h2>
          {nextRecesses.map(recess =>
            <div key={recess.id}>
              <p><span className="recesses">{recess.description}</span>: {moment(recess.startDate).format('DD/MM')} - {moment(recess.endDate).format('DD/MM')}</p>
              <p><b>{_e({ id: "academicSuport.schedule" })}:</b> {recess.openTime} - {recess.closeTime}</p>
            </div>)}
        </div>
      </div>
    </Container>
  );
}

export default AcademicSuport;
