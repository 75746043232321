import React from "react";
import { Switch } from "react-router-dom";

import { ROUTES } from "~/constants/routes";
import Announcements from "~/pages/Announcements";
import AcademicSuport from "~/pages/AcademicSuport";
import Classroom from "~/pages/Classroom";
import Course from "~/pages/Course";
import Dashboard from "~/pages/Dashboard";
import Library from "~/pages/Library";
import LostPassword from "~/pages/LostPassword";
import MyCourses from "~/pages/MyCourses";
import MyNotes from "~/pages/MyNotes";
import Notifys from "~/pages/Notifys";
import ResetPassword from "~/pages/ResetPassword";
import Search from "~/pages/Search";
import SignIn from "~/pages/SignIn";
import ViewAnnouncement from "~/pages/ViewAnnouncement";
//import Faq from "~/pages/FAQ";
import WorkInProgress from "~/pages/WorkInProgress";

import Route from "./Routes";

export default function Routes() {
  return (
    <Switch>
      <Route path={ROUTES.DEFAULT} exact component={SignIn} />
      <Route path={ROUTES.LOST_PASSWORD} exact component={LostPassword} />
      <Route path={ROUTES.RESET_PASSWORD} exact component={ResetPassword} />

      <Route path={ROUTES.DASHBOARD} component={Dashboard} isAccount />
      <Route
        path={`${ROUTES.COURSE}/:courseName/:course_id`}
        component={Course}
        isPrivate
      />
      <Route
        path={ROUTES.MY_COURSES}
        component={MyCourses}
        isPrivate
        isAccount
      />
      <Route path={ROUTES.MY_NOTES} component={MyNotes} isPrivate isAccount />
      <Route path={ROUTES.LIBRARY} component={Library} isPrivate isAccount />
      <Route path={ROUTES.NOTIFYS} component={Notifys} isPrivate />
      <Route
        path={ROUTES.ANNOUNCEMENTS}
        exact
        component={Announcements}
        isPrivate
      />
      <Route
        path={ROUTES.COURSE_STATISTICS}
        isAccount
        component={WorkInProgress}
      />
      <Route path={ROUTES.TUTOR} isAccount component={WorkInProgress} />
      <Route path={ROUTES.MY_MARKS} isAccount component={WorkInProgress} />
      <Route path={ROUTES.PROFILE} isAccount component={WorkInProgress} />
      <Route path={ROUTES.FINANCIAL} isAccount component={WorkInProgress} />
      <Route
        path={ROUTES.MY_CERTIFICATES}
        isAccount
        component={WorkInProgress}
      />
      {/* <Route path={ROUTES.FAQ} isAccount component={Faq} /> */}
      <Route path={ROUTES.FAQ} isAccount component={WorkInProgress} />
      <Route
        path={ROUTES.WORK_IN_PROGRESS}
        isAccount
        component={WorkInProgress}
      />
      <Route
        path={`${ROUTES.ANNOUNCEMENTS}/:id_comunicado`}
        component={ViewAnnouncement}
        isPrivate
      />
      <Route path={ROUTES.SEARCH} component={Search} isPrivate />
      
      <Route
        path={`${ROUTES.CLASSROOM}/:course_id`}
        component={Classroom}
        isPrivate
      />

      <Route path={ROUTES.ACADEMIC_SUPORT} component={WorkInProgress} isAccount />
      {/* <Route path={ROUTES.ACADEMIC_SUPORT} component={AcademicSuport} isAccount /> */}
    </Switch>
  );
}