/* eslint-disable no-console */
import { toast } from "react-toastify";

import { takeLatest, call, put, all, select } from "redux-saga/effects";

import { API } from "~/constants/routes";
import api from "~/services/api";
import { userInfo } from "~/store/selectors";

import { getModuleSuccess } from "./actions";

function* getModuleSubjects(courseModule) {
  const { crs_modulo, mat_modulo } = courseModule;
  const { id_matricula } = yield select(userInfo);

  const responseModuleSubjects = yield call(api.get, API.SUBJECTS_LIST, {
    id_modulo: crs_modulo.id_modulo,
    id_grade: crs_modulo.id_grade,
    id_matricula,
  });
  if (responseModuleSubjects.data.errors.length)
    throw new Error(responseModuleSubjects.data.errors[0].message);

  const moduleSubjects = responseModuleSubjects.data.data;

  return {
    id: crs_modulo.id_modulo,
    number: crs_modulo.nr_modulo,
    title: crs_modulo.ds_modulo,
    dateUnlock: mat_modulo.dt_inicio,
    discipline: moduleSubjects.length,
    grade: crs_modulo.id_grade,
    classes: moduleSubjects.map((disciplina) => ({
      crs_disciplina: disciplina.crs_disciplina,
      aulas: disciplina.capitulo.reduce(
        (curr, cap) => curr.concat(cap.aula),
        []
      ),
    })),
  };
}

export function* getModule({ payload }) {
  const { id_matricula } = payload;
  const { id_login } = yield select(userInfo);

  try {
    const responseModules = yield call(api.get, API.MODULES_LIST, {
      id_login,
      id_matricula,
    });
    if (responseModules.data.errors.length)
      throw new Error(responseModules.data.errors[0].message);

    const modules = responseModules.data.data;
    const modulesWithSubjects = yield all(modules.map(getModuleSubjects));

    yield put(getModuleSuccess(modulesWithSubjects));
  } catch (err) {
    toast.error("Erro ao carregar dados!");
  }
}

export default all([takeLatest("@module/GET_MODULE_REQUEST", getModule)]);
