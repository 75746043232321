import React, { useState, useEffect } from "react";
import {
  MdKeyboardArrowDown,
  MdNotificationsNone,
  MdSearch,
  MdNotifications,
} from "react-icons/md";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { useTheme } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ContainerMD from "@material-ui/core/Container";
import { Form } from "@unform/web";
import { parseISO } from "date-fns";

import { Search } from "~/components";
import { ROUTES } from "~/constants/routes";
import { Avatar, LogoWhite } from "~/images";
import { signOut } from "~/store/modules/auth/actions";
import { getNotifysRequest } from "~/store/modules/notifys/actions";
import { getSearchRequest } from "~/store/modules/search/actions";
import { formatDate } from "~/utils/date";

import { Header } from "./styles";

export default function HeaderTheme() {
  const [account, setAccount] = useState(false);
  const [notify, setNotify] = useState(false);
  const [search, setSearch] = useState(false);
  const theme = useTheme();
  const history = useHistory();

  const { formatMessage: _e } = useIntl();

  const dispatch = useDispatch();
  const { notifications, total } = useSelector((state) => state.notifys);
  const { userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getNotifysRequest({ from: 0, to: 5 }));
  }, [dispatch]);

  function handleAccount() {
    setAccount(!account);
    setNotify(false);
  }

  function handleNotify() {
    setNotify(!notify);
    setAccount(false);
  }

  const handleClickAway = () => {
    setNotify(false);
    setAccount(false);
  };

  const handleLogout = () => {
    dispatch(signOut());
  };

  const handleSearch = (data) => {
    dispatch(getSearchRequest(data.search));
    history.push(`${ROUTES.SEARCH}`);
  };

  /**
   * @todo alt com texto hardcode em 'img com alt="logo"
   */

  return (
    <Header>
      <ClickAwayListener onClickAway={handleClickAway}>
        <ContainerMD maxWidth="lg">
          <div className="header-wrapper">
            <div className="left">
              <Link to={ROUTES.DASHBOARD}>
                <img src={LogoWhite} alt="logo" />
              </Link>
            </div>
            <div className="right">
              <Form
                className={`form-search ${search && "active"}`}
                onSubmit={handleSearch}
              >
                <Search
                  placeholder={_e({ id: "header.searchHere" })}
                  name="search"
                  type="text"
                  handleClose={() => setSearch(false)}
                />
              </Form>
              <div className="area-icons">
                <button type="button" onClick={() => setSearch(true)}>
                  <MdSearch
                    color={theme.palette.tertiary[theme.palette.type]}
                    size={20}
                  />
                </button>
                <button type="button" onClick={handleNotify}>
                  {notify ? (
                    <MdNotifications
                      color={theme.palette.tertiary[theme.palette.type]}
                      size={20}
                    />
                  ) : (
                    <MdNotificationsNone
                      color={theme.palette.tertiary[theme.palette.type]}
                      size={20}
                    />
                  )}
                  {total > 0 && <span className="noti-number">{total}</span>}
                </button>
                <div className={notify ? "notifys active" : "notifys"}>
                  <div className="top">
                    <div className="title-box">
                      {_e({ id: "header.notifications" })}
                    </div>
                    <Link to={ROUTES.NOTIFYS} className="show-all">
                      {_e({ id: "header.viewAll" })}
                    </Link>
                  </div>
                  {notifications.length > 0 && (
                    <ul>
                      {notifications.slice(0, 5).map(({ mat_notificacao }) => (
                        <li key={String(mat_notificacao.id_notificacao)}>
                          <div className="content">
                            <div className="category">
                              {mat_notificacao.ds_titulo}
                            </div>
                            <div className="title-noti">
                              {mat_notificacao.ds_notificacao}
                            </div>
                            <div className="time">
                              {formatDate(
                                parseISO(mat_notificacao.dt_cadastro)
                              )}
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
              <div className="area-account">
                <div className={account ? "account active" : "account"}>
                  <button
                    type="button"
                    className="content"
                    onClick={handleAccount}
                  >
                    <div className="user-info">
                      <span>
                        <img src={Avatar} alt="Avatar" className="avatar" />
                      </span>
                      <h6 className="name">{userInfo.ds_nome}</h6>
                    </div>
                    <MdKeyboardArrowDown
                      color={theme.palette.tertiary[theme.palette.type]}
                    />
                  </button>
                  <div className="account-overlay">
                    <ul>
                      <li>
                        <Link to={ROUTES.WORK_IN_PROGRESS}>
                          {_e({ id: "header.settings" })}
                        </Link>
                      </li>
                      <li>
                        <Link to={ROUTES.WORK_IN_PROGRESS}>
                          {_e({ id: "header.financial" })}
                        </Link>
                      </li>
                      <li>
                        <Link to={ROUTES.PROFILE}>
                          {_e({ id: "header.myProfile" })}
                        </Link>
                      </li>
                      <li>
                        <Link to={ROUTES.ACADEMIC_SUPORT}>
                          {_e({ id: "header.academicSuport" })}
                        </Link>
                      </li>
                    </ul>
                    <Link
                      to={ROUTES.DEFAULT}
                      onClick={handleLogout}
                      className="exit"
                    >
                      {_e({ id: "header.exit" })}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ContainerMD>
      </ClickAwayListener>
    </Header>
  );
}
