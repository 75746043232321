import React from "react";

import PropTypes from "prop-types";

import { Title } from "./styles";

export default function TitleTheme({ children }) {
  return <Title>{children}</Title>;
}

TitleTheme.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
    PropTypes.object
  ]).isRequired
};
