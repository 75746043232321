const enUS = {
    durationHours: "Duration in hours: ",
    durationMonths: "Duration in months: ",
  };
  
  const ptBR = {
    durationHours: "Duração em horas: ",
    durationMonths: "Duração em meses",
  };
  
  export default { enUS, ptBR };