export const API = Object.freeze({
  SESSIONID_GENERATE: "/sessionid/generate",
  NOTES_LIST: "/anotacoes/buscar",
  NOTES_CREATE: "/anotacoes/gravar",
  PASSWORDRESET_RECOVER: "/esqueceusenha/recuperar",
  PASSWORDRESET_REQUEST: "/esqueceusenha/solicitar",
  SEARCH_COURSE: "/busca/curso",
  SEARCH_SUBJECT: "/busca/disciplina",
  SEARCH_MODULE_SUBJECT: "/busca/modulo/disciplina",
  COMMENTS_LIST: "/comentarios/buscar",
  COMMENTS_CREATE: "/comentarios/gravar",
  COMMENTS_REPLY_CREATE: "/comentarios/respostas/gravar",
  COMMENTS_FILE_DELETE: "/comentarios/deletar",
  MODULES_LIST: "/modulo/listar",
  FAVORITESUBJECTS_LIST: "/favoritos/listar",
  FAVORITESUBJECTS_CREATE: "/favoritos/gravar",
  FAVORITESUBJECTS_EDIT: "/favoritos/atualizar",
  LOGIN: "/login/logar",
  PROFILE_LIST: "/meuperfil/listar",
  PROFILE_UPDATE: "/meuperfil/atualizar",
  TEACHERS_LIST: "/professores/listar",
  PHONESTYPES_LIST: "/professores/listar",
  DASHBOARD_LIST: "/dashboard/listar",
  COURSES_LIBRARY_LIST: "/cursos/biblioteca",
  COURSES_LIST: "/cursos/buscar",
  RELATED_COURSES: "/cursos/buscar-relacionados",
  ANNOUNCEMENTS_LIST: "/comunicado/listar",
  NOTIFICATIONS_LIST: "/notificacoes/listar",
  NOTIFICATIONS_UPDATE: "/notificacoes/atualizar",
  SUBJECTS_LIST: "/disciplina/listar",
  LIKEDISLIKE_CREATE: "/likeaula/gravar",
  LIKEDISLIKE_UPDATE: "/likeaula/atualizar",
  GET_VIDEO: "/player",
  FAQ_SEARCH: "/faq/buscar",
  FAQ_CATEGORIES: "/faq/buscar-categoria",
});

export const ROUTES = Object.freeze({
  DEFAULT: "/",
  LOST_PASSWORD: "/solicitar-senha",
  RESET_PASSWORD: "/redefinir-senha/:token",
  DASHBOARD: "/dashboard",
  COURSE: "/curso",
  WORK_IN_PROGRESS: "/pagina-em-construcao",
  MY_COURSES: "/meus-cursos",
  COURSE_STATISTICS: "/estatisticas-de-estudo",
  TUTOR: "/fale-com-o-tutor",
  MY_MARKS: "/minhas-notas",
  FAQ: "/faq",
  MY_CERTIFICATES: "/meus-certificados",
  MY_NOTES: "/minhas-anotacoes",
  NOTIFYS: "/notificacoes",
  PROFILE: "/meu-perfil",
  CONFIG: "/configuracoes",
  FINANCIAL: "/financeiro",
  LOGIN: "/login/logar",
  CLASSROOM: "/sala-aula",
  ANNOUNCEMENTS: "/comunicados",
  LIBRARY: "/biblioteca",
  SEARCH: "/pesquisa",
  ACADEMIC_SUPORT: "/ajuda-academica",
});

export const ANCHOR = Object.freeze({
  ABOUT_COURSE: "#about-course",
});
