const enUS = {
  progress: "Course in progress",
  phases: "phases",
  start: "Start",
  termination: "Termination",
  situation: "Situation",
  completionDocs: "Completion Docs",
  subjects: "Subjects",
  concluded: "concluded",
  continue: "Continue"
};

const ptBR = {
  progress: "Curso em andamento",
  phases: "etapas",
  start: "Início",
  termination: "Término",
  situation: "Situação",
  completionDocs: "Docs p/ conclusão",
  subjects: "Disciplinas",
  concluded: "concluído",
  continue: "Continuar"
};

export default { enUS, ptBR };
