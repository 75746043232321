import { create } from "apisauce";

const api = create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    key: process.env.REACT_APP_API_KEY,
    srckey: process.env.REACT_APP_API_SECRET,
  },
});

export const sessionApi = create({
  baseURL: process.env.REACT_APP_API_URL_SESSION,
  headers: {
    key: process.env.REACT_APP_API_KEY,
    srckey: process.env.REACT_APP_API_SECRET,
  },
});

export const videoApi = create({
  baseURL: process.env.REACT_APP_API_VIDEO_URL,
  headers: {
    key: process.env.REACT_APP_API_VIDEO_KEY,
    srckey: process.env.REACT_APP_API_SECRET,
  },
});

export const fakeApi = create({
  baseURL: process.env.REACT_APP_FAKE_API_URL,
  headers: {
    key: process.env.REACT_APP_API_KEY,
    srckey: process.env.REACT_APP_API_SECRET,
  },
});

export default api;