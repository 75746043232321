export function getRelatedCourseRequest(id_area, id_nivel) {
  return {
    type: "@relatedCourse/GET_RELATED_COURSE_REQUEST",
    payload: {id_area, id_nivel},
  };
}

export function getRelatedCourseSuccess(data) {
  return {
    type: "@relatedCourse/GET_RELATED_COURSE_SUCCESS",
    payload: { data },
  };
}

export function getRelatedCourseFailure(data) {
  return {
    type: "@relatedCourse/GET_RELATED_COURSE_FAILURE",
    payload: { data },
  };
}